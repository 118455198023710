import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Outdoor_Cameras/IN-9010_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9010 Full HD Manual Lense Adjustment",
  "path": "/Outdoor_Cameras/IN-9010_HD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-9010 Full HD Indoor IP Camera Manual Lense Adjustment",
  "image": "../P_SearchThumb_IN-9010HD.png",
  "social": "/images/Search/P_SearchThumb_IN-9010HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9010HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-9010 Full HD Manual Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-9010 Full HD Indoor IP Camera Manual Lense Adjustment' image='/images/Search/P_SearchThumb_IN-9010HD.png' twitter='/images/Search/P_SearchThumb_IN-9010HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9010_HD/Objektivjustage/' locationFR='/fr/Outdoor_Cameras/IN-9010_HD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9010-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9010-full-hd-manual",
        "aria-label": "in 9010 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9010 Full HD Manual`}</h2>
    <h3 {...{
      "id": "lense-adjustment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lense-adjustment",
        "aria-label": "lense adjustment permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lense Adjustment`}</h3>
    <p>{`The IN-9010 Full HD has an autofocus lens integrated with a focal length between 2.8mm to 12mm. Meaning that the lens offers you a diagonal field of view between 36 and 120 degrees. Thanks to an advanced algorithm, the autofocus lens will always adjust itself to the optimal position.`}</p>
    <p>{`Please note that the autofocus lens requires a few seconds until it reaches its focal point and delivers a sharp image.  During the autofocusing period, please note that the pan/ tilt will be temporarily paused. Once the lens has adjusted its best focal point with a clear photo, you will be able to execute the pan/tilt movement again.`}</p>
    <p>{`Users should always AVOID tampering with the lense unit and MUST NEVER try to adjust the lens manually. Any manual adjustment on the lenses mechanical parts will result in a permanent loss of focus.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      